@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&display=swap');

* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:100vh;
  max-width: 100%;
}


.bg-video {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}


.App-header {
  margin-top: 0;
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-logo {
  position: relative;
  display:block;
  margin-top: 100px;
  max-width: 100%;
  height: 60vmin;
  pointer-events: none;
  align-items: center;
  height: auto;
  width: 30vw;
  justify-content: center;
  bottom: -8vh;
}

.area-superior {
  display: flex;
  flex-direction: row ;
  justify-content: center ;
  align-items: center;
  padding: 2vh 0 30vh 0;
  width: 100vw;
  /* background-color: rgba(48, 128, 171, 0.261); */
  background-color: rgba(149, 156, 161, 0.084);

}
.fundo-central {

  border-radius: 30px;
  display:block;
  margin-top: 100px;
  max-width: 100%;
  height: 60vmin;
  pointer-events: none;
  align-items: center;
  height: auto;
  width: fit-content;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .App-logo {
    margin-top: 5vmin;
    max-width: 70%;
  }
  .App-header {
    margin-top: 30vmin;
    overflow: hidden;
  }
  .bg-video {
    overflow: hidden;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.graybox {
  opacity: 0.88;
  margin-bottom: 30px;
  background-color: #eeeeee; 
  height: 11vh; 
  width: 100vw; 
  position: fixed; 
  top: 0; 
  left: 0; 
  z-index: 999; 
  display: flex; 
  align-items: center; 
  padding: 0 20px; 
  box-sizing: border-box; 
}

@media only screen and (max-width: 768px) {
  .graybox {
    height: 14vh;
    justify-content: end;
    opacity: 0.7;
  }
  .content {
    display:none;
  }
}

.content {
  margin-left: 10px; /* Espaço à esquerda para a logo */
}

.logoh {
  height: 60px; /* Altura da logo */
  width: auto; /* Largura automática (mantém a proporção) */
  z-index:1000;
}

.texto {
  margin-top: 40vh;
  text-align: center;
  color: #747272; /* Cor do texto */
  font-family: "Josefin Sans", sans-serif;
  font-size: 50px;
  margin-left:0;
  margin-right:0;
}

.texto-produto {
  margin-top: 40vh;
  text-align: center;
  color: #747272;
  font-family: "Josefin Sans", sans-serif;
  font-size: 22px;
  margin-left:0;
  margin-right:0;
}

.texto-contato {
  margin-left: 30%;
  max-width:100vmin;
  position: absolute;
  margin-top: 12vh;
  text-align: center;
  color: #525252;
  font-family: "Josefin Sans", sans-serif;
  font-size: 24px;
  z-index:500;
}

.texto-menor {
  padding: 1vw;
  max-width:75%;
  margin-top: 20vh;
  margin-bottom: 20vh;
  text-align: center;
  color: #d3c6c6; /* Cor do texto */
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.8rem;
  font-weight:10;
  line-height: 1.4;
  background-color: rgba(121, 112, 112, 0.558);
  border-radius: 20px;
  /* text-shadow: 
    -1px -1px 0 black,  
     1px -1px 0 black,
    -1px  1px 0 black,
     1px  1px 0 black; */
}

.texto-apresentacao-cima {
  padding: 3vh 5vw;
  margin: 15vh 5vw;
  max-width:75%;
  margin-top: 20vh ;
  text-align: center;
  color: #d3c6c6; 
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4;
  background-color: rgba(121, 112, 112, 0.688);
  border-radius: 20px;
  /* text-shadow: 
    -1px -1px 0 black,  
     1px -1px 0 black,
    -1px  1px 0 black,
     1px  1px 0 black; */
}





@media only screen and (max-width: 768px) {
  .texto{
    justify-content:center;
    max-width: 80%;
    margin-left:7%;
    
  }
  .texto-produto{
    margin-top:10vh;
    margin-left:12%;
    text-align:center;
    max-width: 80%;
    font-size: 18px;
  }
  .texto-menor{
    max-width: 80%;
  }
  .texto-contato{
    margin-top:120px;
    margin-left: 20px;
    color:#ffffff;
    max-width:200px;
    text-align: right;
  }
}

.texto p{
  font-size: 23px;
}

.textoPai{
  display: flex;
  justify-content: center;
}

.linha-cinza {
  border-top: 1px solid #ccc;
  margin-top: 30px auto 0;
  width: 100%;
}

.imagem-lateral {
  height: 550px;
  width: 400px;
  position:absolute;
}

.imagem-lateral img {
  height: 100%;
  width: 100%; 
  clip-path: polygon(0 0, 50% 0, 74% 80%, 0% 100%);
  position: absolute;
  object-fit: cover;
}

.PorquegeralPC{
  display:flex;
  width:100%;
}

.PorqueGeralMobile{
  display: flex;
  margin-top: 10vh;
  justify-content: center;
  width:100%;
  display:none;
}

@media only screen and (max-width: 768px) {
  .PorqueGeralPC{
    display:none;
  }
  .PorqueGeralMobile{
    display:flex;
    width: 100vw;
  }
  .imagem-lateral {
    height: 10vh;
    object-fit: cover;
    position:fixed;
    margin-right:0;
    padding-right:0;
  }

  .imagem-lateral img {
    position: relative;
    bottom: -100vh;
    margin-left:-10px;
    height: 50vh;
    width: 110vw; 
    clip-path: none;
    position:absolute;
  }

  .texto-produto{
    position: relative;
    left: -1vw;
    margin-top:10vh;
    text-align:center;
    max-width: 80%;
    font-size: 18px;
  }
}

.TelaContato-contato{
  margin-top:100vh;
}

#contato-container {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: #dadada;
  border: none;
  border-radius: 20px;
  padding: 10px;
  transition: opacity 3s ease-in-out;
  opacity: 1;
  margin-left: 20px;
  margin-bottom: 10px;
  width: 240px;
  height: 160px;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 15% 85%, 0 100%);
  z-index:500;
}

#contato-content {
  text-align: left;
  justify-content: start;
  font-family: "Josefin Sans", sans-serif;
  font-size: 11px;
  color: #5e5e5e;
  padding: 18px;
  line-height: 1.5;
  margin-top: -8px;
}

#contato-container.hidden {
  opacity: 0;
}

.texto-linkado:hover {
  text-decoration: underline;
}


.conteiner-links{
  background-color:#ddddddcc;
  width:100%;
  height:250px;
  margin-bottom:130px;
  align-content:center;
}

.fraseseta{
  width:auto;
  padding:12px;
  display:flex;
  flex-direction: row;
  margin-left:120px;
}


.texto-linkado{
  font-family:'Josefin Sans';
  color:#4a4a4a;
  margin-left: 35px;
  margin-top: 3px;
}

.logoParceiroPai{
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  margin-left:40px;
  margin-top:-40px;
}

.logoParceiro{
  width: auto;
  height: 130px;
}

.texto-parceiro{
  margin-left: 60px;
  font-family:'Josefin Sans';
  color:#4a4a4a;
  font-size: 30px;
  margin-top:50px;
  margin-bottom: 50px;
}

.area-parceiros{
  padding: 0 0 10vh 0;
  justify-content: center;
  width: 100vw;
  margin: 0;
  background-color: rgba(130, 127, 127, 0.089);
}

.area-parceiros-superior{
  padding: 1vh 0 5vh 0;
  justify-content: center;
  width: 100vw;
  background-color: white;
}

.container-logos{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5vh;
  width: 100vw;
  background-color: white;
  height: 50vh;
  gap: 2vw;
}

@media only screen and (max-width: 768px) {
  .divisoria-cinza{
    margin-top:20px;
    background-color:#ddd;
    width:100%;
    height:280px;
    margin-bottom:130px;
  }

  .espaco-dentro-div {
    padding-top: 6%;
  }
  
  .fraseseta{
    width:80%;
    padding:15px;
    display:flex;
    flex-direction: row;
    margin-left:80px;
  }
  
  .texto-linkado{
    max-width: 85%;
    font-family:'Josefin Sans';
    font-size: 18px;
    color:#4a4a4a;
    margin-left: 15px;
    line-height: 1.5;
  }

  .seta {
    color: #4a4a4a;
    width: 17px;
    height: 17px;
    margin-top: 6px;
  }

  #contato-container {
    display:none;
  }
  
  #contato-content {
    display:none;
  }
}