@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&display=swap');


.navbar {
  overflow-y: hidden;
  background-color: white !important;
}
.menu{
  margin-top: 20vh;
  padding: 1.5vmin 0;
  display: flex;
  text-align: end;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  left: auto;
  height: 40vh;
  top: 0; 
  z-index: 1000;
  margin-top: 6px;
  margin-right: 5vmin;
  overflow-y: hidden;
}

.listaMenu{
  margin-top: 1.8vmin;
  position: fixed;
  text-align: center;
  justify-content:flex-end;
  list-style-type: none;
  margin-right: 3vmin;
  margin-left:0;
  padding: 1.05vh 1vw;
  display: flex;
  flex-wrap: wrap;
  /* background-color: rgba(255, 255, 255, 0.098) ; */
  border-radius: 8px;
}



@media only screen and (max-width: 768px) {
  .menu {
    justify-content:start;
    margin-top: 2px;
    text-align: start;
    left: 0;
    right: auto;
  }
  .listaMenu {
    justify-content:flex-start;
    text-align: start;
    max-width: 40vmin;
  }
  .botaoNavegacao{
    margin-right: 1vmin;
    padding: 1vmin 1vmin;
  }

}

.botaoNavegacao {
  padding: 1vmin 3vmin;
  margin-right: 3vmin;
  text-decoration: none;
  /* color: #767272;  */
  color: #dcd8d8; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Josefin Sans", sans-serif;
  font-size: 16px;
  font-optical-sizing: auto;
  font-weight: 700px;
  font-style: normal;
  position: relative;
  overflow: hidden;
  z-index: 2;

  
}

.botao-pai {
  overflow:hidden;
  
}

.botaoNavegacaoAtivo {
  padding: 1vmin 3vmin;
  margin-right: 3vmin;
  text-decoration: none;
  color: #14BBC7;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Josefin Sans", sans-serif;
  font-size: 16px;
  font-optical-sizing: auto;
  font-weight: 700px;
  font-style: normal;
  z-index: 2;
  height: 10vh;
}

.botaoNavegacao:hover {
  color: #f0bb48; 
  font-size: 19px;
}

.link{
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}


.arealogos {
  position: relative;
  top: -10.7vh;
  left: -37vw;
}

.logonavbar {
  width: 9vw;
  height: auto;
}

.fundonavbar {
  position: relative;
  top: 0.4vh;
  width: 11vw;
  opacity: 1.2  5;
}




