  .catalogo {
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
  }
  
  .piscina-grid {
    position: relative;
    left: 0vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 90px;
  }
  
  .piscina-item {
    width: 250px;
    height: 300px;
    position: relative;
    cursor: pointer;

  }

  .piscina-item-AzulVinil {
    width: 250px;
    height: 300px;
    position: relative;
    cursor: pointer;

  }

  .piscina-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  .piscina-item-AzulVinil img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    border:5px solid #14bbc7b9;
  }
  
  .overlay {
    position: absolute;
    border-radius: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #14BBC7; /* Azul transparente */
    opacity: 0; /* Inicialmente transparente */
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    z-index: 2;
  }

  .overlay-AzulVinil {
    position: absolute;
    border-radius: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #14BBC7; /* Azul transparente */
    border:5px solid #14bbc7b9;
    opacity: 0; /* Inicialmente transparente */
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    z-index: 2;
  }

  .piscina-item:hover .overlay {
    opacity: 0.7;
  }

  

  
  .overlay p {
    color: white;
    font-weight: normal;
    font-family: "Josefin Sans", sans-serif;
    font-size: 22px;
  }

  .espaco-botao {
    display: flex;
    justify-content: center;
    align-items: end;
    width: auto;
    height: auto;
    padding-top: 50px;
  }

  .botao-fechar-piscina {
    display: flex;
    justify-content: center;
    width: 120px;
    height: 40px;
    border-radius: 20px;
    background-color: rgb(58, 113, 233);
    align-items: center;
    color: white;
    font-family: "Josefin Sans", sans-serif;
    font-size: 18px;
    border: none;
    cursor: pointer;
    text-align: center;
    padding-top: 5px;
  }

  .espaco-text {
    display: flex;
    flex-direction: column;
    justify-content: left;
    flex: 1;
    align-items: left;
    padding-top: 20px;
    padding-left: 20px;
    width: 850px;
    height: auto;
  }

  .details-text {
    width: 100%;
    height: auto;
    font-family: "Josefin Sans", sans-serif;
    color: rgb(73, 73, 73);
    font-size: 22px;
    text-align: left;
    line-height: 1.3;
    font-weight: lighter;
  }

  .titulo-piscina {
    width: 100%;
    height: auto;
    font-family: "Josefin Sans", sans-serif;
    color: rgb(145, 145, 145);
    font-size: 50px;
    text-align: left;
    padding-bottom: 50px;
  }


  .esquerda {
    width: 1000px;
    height: auto;
  }

  .espaco-img {
    align-items: flex-end;
    justify-content: flex-end;
    height: 380px;
    width: 400px;
    border-radius: 50px;
  }
  
  .details-content img {
    height: 100%;
    width: 100%;
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
    align-self: flex-end;
    border-radius: 40px;
    object-fit: cover;
  }

  .details-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }

  .details-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(235, 235, 235);
    padding: 20px;
    border: none;
    border-radius: 50px;
    width: 1100px;
    height: 400px;
    overflow-y: auto;
    z-index: 2;
    box-shadow: 0px 0px 10px rgba(131, 131, 131, 0.5);
  }

  @media only screen and (max-width: 768px) {
    .piscina-item img {
      max-width: 100%;
      height: 20vh;
      padding:0;
      height: 100%;
    }

    .piscina-item {
      cursor: pointer;
      padding:0;
      height: 300px;
      width: 300px;
 
    }

    .piscina-grid {
      position: relative;
      left: -4vw;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 90px;
    }

    .catalogo{
      justify-content: center;
      margin-left:10%;
      padding:0;
    }

    .overlay{
      position: absolute;
      border-radius: 20px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #14BBC7; /* Azul transparente */
      opacity: 0; /* Inicialmente transparente */
      transition: opacity 0.3s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      z-index: 2;
    }

    .piscina-item:hover .overlay {
      opacity: 0.7;
    }
     
    .overlay p {
      color: white;
      font-weight: normal;
      font-family: "Josefin Sans", sans-serif;
      font-size: 18px;
    }

    .details-modal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgb(235, 235, 235);
      padding: 20px;
      border: none;
      border-radius: 50px;
      width: 80%;
      height: 70%;
      overflow-y: auto;
      z-index: 2;
      box-shadow: 0px 0px 10px rgba(131, 131, 131, 0.5);
    }

    .details-content {
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
    }

    .titulo-piscina {
      margin-top:-240px;
      width: 50%;
      height: auto;
      font-family: "Josefin Sans", sans-serif;
      color: rgb(145, 145, 145);
      font-size: 30px;
      text-align: left;
      padding-bottom: 50px;
    }

    .details-text {
      margin-top:-30px;
      width: 80%;
      margin-right:0px;
      padding-right:0px;
      height: auto;
      font-family: "Josefin Sans", sans-serif;
      color: rgb(73, 73, 73);
      font-size: 22px;
      text-align: left;
      line-height: 1.3;
      font-weight: lighter;
    }

    .details-content img {
      margin-top:220px;
      margin-left:-172px;
      height: 50%;
      width: 420%;
      align-self: flex-end;
      border-radius: 20px;
    }

    .espaco-img {
      align-items: flex-start;
      justify-content: flex-start;
      height: 580px;
      width: 550px;
      border-radius: 50px;
    }

    .esquerda {
      width: 200px;
      height: 80px;
    }

    .espaco-text {
      display: flex;
      flex-direction: column;
      justify-content: left;
      flex: 1;
      align-items: left;
      padding-top: 20px;
      padding-left: 20px;
      width: 850px;
      height: auto;
    }

    .espaco-botao {
    display: flex;
    justify-content: center;
    align-items: end;
    width: auto;
    height: auto;
    padding-top: 270px;
    margin-left:290px;
  }

  .botao-fechar-piscina {
    display: flex;
    justify-content: center;
    width: 120px;
    height: 40px;
    border-radius: 20px;
    background-color: rgb(58, 113, 233);
    align-items: center;
    color: white;
    font-family: "Josefin Sans", sans-serif;
    font-size: 18px;
    border: none;
    cursor: pointer;
    text-align: center;
    padding-top: 5px;
  }
  }