@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&display=swap');

.menu-equipe {
  margin-top: 50px;
  text-align: center;
  margin-left: -6vmin;
}

.item-menu-equipe {
  position:relative;
  align-content: end;
  border: 5px solid #f8a814;
  margin-bottom: 8vmin;
  margin-left: 4vmin;
  background-color: #ffffff;
  max-width: 300px;
  max-height: auto;
  border-radius: 25px;
  cursor: pointer;
}

.nome-item-equipe {
  font-weight: bold;
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
  font-optical-sizing: auto;
  font-weight: 700px;
  font-style: normal;
  margin-right: 5px;
  color: #f4e7e7;
  background-color: #f8a814;
  padding-top: 2.4vmin;
  padding-bottom: 1.5vmin;
  width: 100%;
  border-radius: 20px;
}

.preco-item-equipe{
  font-weight: bold;
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
  font-optical-sizing: auto;
  font-weight: 700px;
  font-style: normal;
  padding-bottom: 0px;
}

.lista-menu-equipe {
  display: flex ;
  flex-wrap: wrap;
  justify-content: center;
}

.image-equipe{
  margin-top: auto;
  margin-right:auto;
  max-width: 300px;
  height: fit-content;
  border-radius: 25px;
}

@media only screen and (max-width: 768px) {
  .item-menu-equipe{
    max-width: 100px;
    border-radius: 20px;
  }
  .image-equipe {
    max-width: 100px;
    border-radius: 20px;
  }
  .nome-item-equipe{
    font-size: 14px;
    border-radius: 12.5px;
  }
  .popup-texto-equipe {
    max-width:300px;
    font-size:10px;
  }

  .popup-content-equipe {
    width:700px;
  }

}

.item-menu:hover .overlay {
  opacity:0.45;
} 

.overlay-equipe {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #14BBC7;
  overflow: hidden;
  width: 100%;
  height:0;
  transition: 5s ease;
}

.overlay-equipe p {
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-family: "Josefin Sans", sans-serif;
  font-size: 22px;
}

.popup-container-equipe {
  position:fixed;
  left:0;
  top:0;
  right:0;
  bottom:0;
  background-color: rgba(56, 89, 126, 0.4); /* Fundo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content-equipe {
  width:40vmin;
  height:fit-content;
  border: 5px solid #14BBC7;
  border-radius: 25px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Sombra */
  align-content: flex-start;
  position: relative;
}

.popup-content-equipe h2 {
  margin-top: 0;
}

.popup-img-equipe {
  margin-top: 10%;
  border: 5px solid #f8a814;
  border-radius: 5px;
  max-width: 55%;
  height: auto;
  margin-bottom: 10px;
}

.popup-content-equipe button {
  margin-top: 5vh;
  background-color: #f8a814;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.popup-content-equipe button:hover {
  background-color: #0056b3;
}

.popup-texto-equipe {
  margin-top:0;
  margin-left:1%;
  text-align: start;
  color: #3a3a3a; /* Cor do texto */
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
}

.popup-titulo-equipe {
  margin-top:0;
  text-align: center;
  color: #3a3a3a; /* Cor do texto */
  font-family: "Josefin Sans", sans-serif;
  font-size: 22px;
}

