.container{
    width: 90%;
    max-width: 1440px;
    height:auto;
    margin: 0 auto;
    z-index: 2;
    overflow: clip;
    
}

@media only screen and (max-width: 768px) {
  .container{
    width: 80%;
    height:60vh;
  }
  .slider-item{
    object-fit: cover;
    width: 100%;
  }

  .slider-item .img {
    width: 100%;
  }
}

.slider-item{
    width: 1440px;
    height: 550px;
    object-fit: cover;
    border-radius: 10px;
    overflow: clip;
}


.swiper-button-prev,
.swiper-button-next {
  color: #fff;
  background-color: none;
  cursor: pointer;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: #14BBC7;
}