@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&display=swap');

.Sobre {
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}



.bg-video-sobre {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

}


.header-sobre {
  overflow-x: hidden;
  margin-top: 0;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;

}

.logo-lifegold {
  position: relative;
  display:block;
  margin-top: 100px;
  height: auto;
  pointer-events: none;
  align-items: center;
  height: auto;
  width: 28vw;    
  bottom: -24vh;
  left: -29vw;

  /* width: 34vw;
  bottom: -10vh;
  left: -25vw; */
}

.area-superior-sobre {
  display: flex;
  justify-content: center ;
  align-items: center;
  flex-wrap: wrap;
  padding: 2vh 0 30vh 0;
  width: 100vw;
  height: 80vh;
  /* background-color: rgba(48, 128, 171, 0.261); */
  background-color: rgba(149, 156, 161, 0.084);
  overflow-y: hidden;
}

.fundo-central-lifegold {
  border-radius: 30px;
  display:block;
  margin-top: 10vh;
  max-width: 100%;
  height: auto;
  pointer-events: none;
  align-items: center;
  width: fit-content;
  justify-content: center;
  width: 60vw;
  overflow-y: hidden;
}

@media only screen and (max-width: 768px) {
  .header-sobre {
    margin-top: 30vmin;
    overflow: hidden;
  }
  .logo-lifegold {
    margin-top: 5vh;
    width: 100vw;
    bottom: -7vh;
    left: 0vw;
  }
  .bg-video-sobre {
    overflow: hidden;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;

  }

  .area-superior-sobre {
    overflow: hidden;
    display: flex;
    justify-content: center ;
    align-items: center;
    flex-wrap: wrap;
    padding: 2vh 0 5vh 0;
    height: 47vh;
    width: 100vw;
    background-color: transparent;
  }

  .fundo-central-lifegold {
    display: none;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.graybox {
  opacity: 0.88;
  margin-bottom: 30px;
  background-color: #eeeeee; 
  height: 70px; 
  width: 100vw; 
  position: fixed; 
  top: 0; 
  left: 0; 
  z-index: 999; 
  display: flex; 
  align-items: center; 
  padding: 0 20px; 
  box-sizing: border-box; 
}

@media only screen and (max-width: 768px) {
  .graybox {
    height: 14vh;
    justify-content: end;
    opacity: 0.7;
  }
  .content {
    display:none;
  }
}

.content {
  margin-left: 10px; 
}

.logoh {
  height: 60px; 
  width: auto; 
  z-index:1000;
}

.texto {
  margin-top: 40vh;
  text-align: center;
  color: #747272; 
  font-family: "Josefin Sans", sans-serif;
  font-size: 50px;
  margin-left:0;
  margin-right:0;
}

.texto-produto {
  margin-top: 40vh;
  text-align: center;
  color: #747272;
  font-family: "Josefin Sans", sans-serif;
  font-size: 22px;
  margin-left:0;
  margin-right:0;
}

.texto-contato {
  margin-left: 30%;
  max-width:100vmin;
  position: absolute;
  margin-top: 12vh;
  text-align: center;
  color: #525252;
  font-family: "Josefin Sans", sans-serif;
  font-size: 24px;
  z-index:500;
}

.texto-menor {
  padding: 1vw;
  max-width:75%;
  margin-top: 20vh;
  margin-bottom: 20vh;
  text-align: center;
  color: #d3c6c6; 
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.8rem;
  font-weight:10;
  line-height: 1.4;
  background-color: rgba(121, 112, 112, 0.558);
  border-radius: 20px;
}

.texto-apresentacao {
  padding: 3vh 5vw;
  margin: 15vh 5vw;
  max-width:75%;
  margin-top: 20vh 0;
  text-align: center;
  color: #d3c6c6; 
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4;
  background-color: rgba(121, 112, 112, 0.688);
  border-radius: 20px;
  /* text-shadow: 
    -1px -1px 0 black,  
     1px -1px 0 black,
    -1px  1px 0 black,
     1px  1px 0 black; */
}

.texto-apresentacao-cima {
  position: relative;
  left: -1vw;
  padding: 3vh 5vw;
  margin: 15vh 5vw;
  max-width:75%;
  margin-top: 20vh 0;
  text-align: center;
  color: #d3c6c6; 
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4;
  background-color: rgba(143, 136, 136, 0.52);
  border-radius: 20px;
  /* text-shadow: 
    -1px -1px 0 black,  
     1px -1px 0 black,
    -1px  1px 0 black,
     1px  1px 0 black; */
}


@media only screen and (max-width: 768px) {
  .texto{
    justify-content:center;
    max-width: 80%;
    margin-left:7%;
    
  }
  .texto-produto{
    margin-top:10vh;
    margin-left:12%;
    text-align:center;
    max-width: 80%;
    font-size: 18px;
  }
  .texto-menor{
    max-width: 80%;
  }
  .texto-contato{
    margin-top:120px;
    margin-left: 20px;
    color:#ffffff;
    max-width:200px;
    text-align: right;
  }
}

.texto p{
  font-size: 23px;
}

.textoPai{
  display: flex;
  justify-content: center;
}

.linha-cinza {
  border-top: 1px solid #ccc;
  margin-top: 30px auto 0;
  width: 100%;
}

.imagem-lateral {
  height: 550px;
  width: 400px;
  position:absolute;
}

.imagem-lateral img {
  height: 100%;
  width: 100%; 
  clip-path: polygon(0 0, 50% 0, 74% 80%, 0% 100%);
  position: absolute;
  object-fit: cover;
}

.PorquegeralPC{
  display:flex;
  width:100%;
}

.PorqueGeralMobile{
  display: flex;
  margin-top: 10vh;
  justify-content: center;
  width:100%;
  display:none;
}

@media only screen and (max-width: 768px) {
  .PorqueGeralPC{
    display:none;
  }
  .PorqueGeralMobile{
    display:flex;
    width: 100vw;
  }
  .imagem-lateral {
    height: 400px;
    object-fit: cover;
    position:absolute;
    margin-right:0px;
    padding-right:0px;
  }

  .imagem-lateral img {
    margin-left:-10px;
    margin-top: 35px;
    margin-bottom :0px;
    height: 932px;
    width: 432px; 
    clip-path: none;
    position:absolute;
  }
}

.TelaContato-contato{
  margin-top:100vh;
}

#contato-container {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: #dadada;
  border: none;
  border-radius: 20px;
  padding: 10px;
  transition: opacity 3s ease-in-out;
  opacity: 1;
  margin-left: 20px;
  margin-bottom: 10px;
  width: 240px;
  height: 160px;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 15% 85%, 0 100%);
  z-index:500;
}

#contato-content {
  text-align: left;
  justify-content: start;
  font-family: "Josefin Sans", sans-serif;
  font-size: 11px;
  color: #5e5e5e;
  padding: 18px;
  line-height: 1.5;
  margin-top: -8px;
}

#contato-container.hidden {
  opacity: 0;
}

.texto-linkado:hover {
  text-decoration: underline;
}

/* links */
.conteiner-links-sobre{
  display: flex;
  flex-wrap: wrap;
  background-color:#ddddddcc;
  width:100%;
  height:30vh;
  margin-bottom:30vh;
  align-content:center;
  justify-content: start;
  padding: 0 0 0 6vw;
}

.fraseseta-sobre{
  width:auto;
  padding:1.2vw;
  display:flex;
}

.seta-sobre {
  color: #4a4a4a;
  width: 4vw;
  height: 2vh;
  margin-top: 6px;
  font-size: 10rem;
}

.texto-linkado-pai{
  font-family:'Josefin Sans';
  color:#4a4a4a;
}

.seta-sobre {
  color: #4a4a4a;
  margin-top: 6px;
  width: 10vw;
  display: block;
  font-size: 20px;

}

.texto-linkado-sobre{
  color:#4a4a4a;
  text-decoration: none;
}



@media only screen and (max-width: 768px) {
  .conteiner-links-sobre{
    display: flex;
    width:100vw;
    height: 80vh;
    flex-direction: column;
    align-content:start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 0 0 8vw;
    gap: 10vh;
  }
  
  .fraseseta-sobre{
    width:75vw;
    padding:1vh;
    display:flex;
    flex-direction: row;
    margin:0;
    justify-content: start;
  }
  
  .seta-sobre {
    color: #4a4a4a;
    margin-top: 6px;
    width: 10vw;
    display: block;
    font-size: 20px;

  }
  
  .texto-linkado-pai{
    position: relative;
    font-family:'Josefin Sans';
    font-size:1.2rem;
    color:#4a4a4a;
    margin: 0 0 0 2vw;
    padding: 0;
    text-align: start;
  }

  .texto-linkado-sobre{
    left: 0;
    font-family:'Josefin Sans';
    width: 40vw;
    font-size:2rem;
    color:#4a4a4a;
    margin: 0;
    padding: 0;
  }
}
/*  */


/* Indicação de piscinas */
.indica-piscina{
  display:flex;
  justify-content: center;
  width:42%;
}

.indica-piscina-link{
  display:flex;
  width:20vw;
  background-color: rgba(143, 136, 136, 0.52);
  padding: 2vw;
  border-radius: 1000px;
}

@media only screen and (max-width: 768px) {
  .indica-piscina{
    display: flex;
    margin-top: 10vh;
    width:100%;
    margin: 0 0 10vh 0;
  }
  .indica-piscina-link{
    position: relative;
    display:flex;
    width:65vw;
    background-color: rgba(117, 111, 111, 0.455);
    padding: 4vw;
    border-radius: 1000px;
    border: 2px solid rgb(163, 156, 156);
  }
}
/*  */

.parceiros {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:30vh;
  max-width: 100%;
}


.logoParceiroPai{
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  margin-left:40px;
  margin-top:-40px;
}

.logoParceiro{
  width: auto;
  height: 130px;
  border-radius: 0px;
  padding: 2vw 5vw;
}


.area-parceiros{
  margin-top:30vh;
  padding: 0 0 10vh 0;
  justify-content: center;
  width: 100vw;
  margin: 0;
  background-color: rgb(255, 255, 255);
}

.area-parceiros-superior-sobre{
  position: relative;
  padding: 1vh 0 5vh 0;
  justify-content: center;
  width: 100vw;
  min-height: 90vh;
  height: max-content;
  max-height: 98vh;
  background-color: rgb(255, 255, 255);
}

.texto-parceiro{
  margin-left: 60px;
  font-family:'Josefin Sans';
  color:#4a4a4a;
  font-size: 30px;
  margin-top:50px;
  margin-bottom: 50px;
}

.container-logos-sobre{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5vh;
  width: 100vw;
  background-color: transparent;
  height: max-content;
  gap: 2vw;
}

.logo-lifegold-parceiros {
  width: 40vw;
  opacity: 0.2;
  position: absolute;
  top: 20%;
  right: 30%;
}

@media only screen and (max-width: 768px) {

  .area-parceiros{
    display: block;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .area-parceiros-superior-sobre{
    height: 160vh;
    max-height: 170vh;
    margin: 0;
    padding: 0.1vh 0 5vh 0;
  }
  
  .texto-parceiro{
    position: relative;
    left: -5vw;
  }


  
  .container-logos-sobre{
    margin: 0;
    position: relative;
    left: -5vw;
  }

  .logo-lifegold-parceiros {
    width: 30vw;
    opacity: 0.3;
    position: relative;
    top: -4vh;
    right: -67vw;
  }
}


@media only screen and (max-width: 768px) {


  #contato-container {
    display:none;
  }
  
  #contato-content {
    display:none;
  }
}





@keyframes appear {
  from {
    opacity: 0;
    clip-path: inset(100% 100% 0 0);
  }
  to {
    opacity: 1;
    clip-path: inset(0 0 0 0);
  }
}

.logo-lifegold {
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}