@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&display=swap');

.form-container {
  position:absolute;
  margin-top:10vh;
  margin-bottom:0;
  margin-left:550px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index:500;
  font-family: "Josefin Sans", sans-serif;
}

form {
  margin-top:0vh;
  margin-left:0px;
  width: 400px;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}

form div {
  margin-left:0px;
  margin-bottom: 20px;
}

form label {
  margin-left:0px;
  display: block;
  margin-bottom: 5px;
  font-family: "Josefin Sans", sans-serif;
}

form input[type="text"],
form input[type="email"],
form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  
}

form textarea {
  height: 100px; 
  margin-right: 10px;
}

form-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

form-button:hover {
  background-color: #0056b3;
}

@media only screen and (max-width: 768px) {
  .form-container {
    position:absolute;
    margin-top:10vh;
    margin-bottom:0;
    margin-left:12vw;
    width:300px;
    height: 100vh;
  }
}