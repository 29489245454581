@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&display=swap');

.contato{
  background-color: #E3E3E3;
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  margin-top: 50vmin;
  padding:0px;
  z-index:3;
  margin-bottom:-10px;
}

.espaco-contatos {
  background-color: none;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}

.a {
  background-color: none;
  width: 30%;
  height: auto;
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-right: 70px;
}

.titulo-contato {
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
  width: 100%;
  height: auto;
  text-align: left;
  margin-top: 70px;
  margin-left: 90px;
  margin-bottom: 20px;
}

.texto-normal {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1rem;
  width: 100%;
  height: auto;
  text-align: left;
  margin-left: 90px;
  margin-bottom: 10px;
  color:rgb(24, 24, 24); 
}

.logo{
  margin-top: 11%;
  height: fit-content;
  display: flex;
}


.fundo{
  margin-right: 0;
  height: 40vmin;
  width: fit-content;
  display: flex;
}


@media only screen and (max-width: 768px) {
  .contato{
    height: 30vh;
    padding: 0;
  }
  .fundo {
    display: none;
  }
  .logo {
    position: relative;
    bottom: -6vh;
    left: -4vw;
    max-width: 15vw;
  }
  .espaco-contatos {
    background-color: none;
    width: 100vw;
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    overflow: scroll;
    gap: 10vw;
  }
  .titulo-contato{
    font-family: "Josefin Sans", sans-serif;
    font-size: 0.1rem;
    width: 10vw;
    height: 1vh;
    margin: 1vh 0 10vh 1vw;
  }
  .a {
    background-color: none;
    width: 30%;
    height: 20vh;
    align-items: end;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  
  .titulo-contato {
    font-family: "Josefin Sans", sans-serif;
    font-size: 20px;
    width: 100%;
    height: auto;
    text-align: left;
    margin-top: 70px;
    margin-left: 90px;
    margin-bottom: 20px;
  }
  .texto-normal{
    margin-top: 0.5vh;
    margin-left:50vmin;
    max-width:30vmin;
    font-size: 12px;
  }
}