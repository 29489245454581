.bubble-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; 
}

.bubble {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 5px solid #e0e0e0;
  border-radius: 50%;
  animation: moveUp 25s linear forwards, fadeOut 5s linear forwards; 
}


@keyframes moveUp {
  from {
    top: top; 
  }
  to {
    top: -100%; 
  }
}


@keyframes fadeOut {
  from {
    opacity: 1; 
  }
  to {
    opacity: 0; 
  }
}